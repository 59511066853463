import styled from 'styled-components';
import { GreenBackgroundZIndex } from '../hero/elements';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  iframe {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    border: 0;
    z-index: ${GreenBackgroundZIndex + 1};
  }
`;
